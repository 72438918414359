/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}
/* @import '@bricks/style-dictionary-release/build/scss/pacas.scss'; */
.title-modal-datail {
	@apply text-neutral-dark text-xl font-medium font-['Montserrat'] leading-[30px];
}

.subtitle-modal-datail-ligth {
	@apply text-zinc-400 text-base font-medium font-['Montserrat'] leading-normal;
}
.subtitle-modal-datail-dark {
	@apply text-neutral-dark text-base font-medium font-['Montserrat'];
}
.subtitle-modal-datail-primary {
	@apply text-primary-medium text-base font-medium font-['Montserrat'];
}
.content-inf-detail {
	@apply inline-flex flex-col items-start justify-start gap-4;
}

.icon-primary-modal {
	@apply text-lg text-primary-medium;
}
.gap-row{
    @apply inline-flex items-center justify-start gap-2
}
.error-message{
    @apply text-critical-medium p-1 text-xs w-96
}
